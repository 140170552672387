import React from 'react';
import { Modal, Result, Button, ModalFuncProps, ModalProps, ResultProps } from 'antd';

type SuccessModalProps = Omit<ModalProps, 'onOk' | 'footer'> & {
  'data-testid'?: String;
  onOk?: () => void;
  continueDataTestid?: string;
  subTitle?: React.ReactNode;
  resultProps?: Omit<ResultProps, 'extra' | 'status' | 'subtitle'>;
};

export const SuccessModal = ({
  title,
  subTitle = null,
  onCancel,
  cancelText = 'OK',
  okText = '',
  'data-testid': dataTestid = '',
  continueDataTestid = '',
  onOk,
  ...rest
}: SuccessModalProps) => (
  <Modal centered footer={null} closable={false} onCancel={onCancel} {...rest}>
    <Result
      status="success"
      title={title}
      subTitle={subTitle}
      extra={[
        <div key={`success-modal-buttons-${dataTestid}`}>
          <Button key="submit" onClick={onCancel} data-testid={dataTestid}>
            {cancelText}
          </Button>
          {okText !== '' && (
            <Button key="next" onClick={onOk} data-testid={continueDataTestid} type={'primary'}>
              {okText}
            </Button>
          )}
        </div>
      ]}
    />
  </Modal>
);

export const successModal = ({ ...otherProps }: ModalFuncProps) =>
  Modal.success({
    centered: true,
    ...otherProps
  });
